import { useAtom } from 'jotai';
import { showToastAtom, toastAtom } from '../state/atoms';
import { toastSeverity } from '../utils/constants';

function useToast() {
  const [, setShowToast] = useAtom(showToastAtom);
  const [, setToast] = useAtom(toastAtom);

  const showError = (message, duration) => {
    showToast(toastSeverity.ERROR, duration, message);
  };

  const showWarning = (message, duration) => {
    showToast(toastSeverity.WARNING, duration, message);
  };

  const showInfo = (message, duration) => {
    showToast(toastSeverity.INFO, duration, message);
  };

  const showSuccess = (message, duration) => {
    showToast(toastSeverity.SUCCESS, duration, message);
  };

  const showToast = (severity, duration, message) => {
    setShowToast(true);
    setToast({ severity, duration, message });
  };

  return { showError, showWarning, showInfo, showSuccess };
}

export default useToast;
