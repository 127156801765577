import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar } from '@mui/material';
// hooks
import { useOffSetTop, useResponsive } from '../../../../shared/hooks';
// utils
import cssStyles from '../../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import { Logo, Iconify } from '../../../../shared/components/base';
import { IconButtonAnimate } from '../../../../shared/components/animate';
import AccountPopover from './AccountPopover';
import ThemePopover from './ThemePopover';
import NotificationsPopover from './NotificationsPopover';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import firebase from 'firebase';
import { firestoreKeys } from 'src/shared/utils/constants';


// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset',
})(({ isCollapse, isOffset, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...{
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    },
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false }) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT);

  const isDesktop = useResponsive('up', 'lg');

  const [usersRef] = useCollectionData(firebase.firestore().collection(firestoreKeys.USERS), { idField: 'id' });


  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <ThemePopover />
          <NotificationsPopover users={usersRef} />
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
