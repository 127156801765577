const calculateAgeGroup = (dateString) => {
  if (dateString !== '') {
    const ageGroups = ['0-17', '18-25', '26-35', '36-45', '46-55', '56-65'];

    const today = new Date();
    const dateOfBirth = new Date(dateString);
    const age = today.getFullYear() - dateOfBirth.getFullYear();

    const x = ageGroups.find((e) => {
      const s = e.split('-');
      return age >= s[0] && age <= s[1];
    });    

    return x;
  }
  return '';
};

export default calculateAgeGroup;
