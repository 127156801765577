import PropTypes from 'prop-types';
// utils
import createAvatar from '../../../utils/createAvatar';
// components
import Avatar from './Avatar';

// ----------------------------------------------------------------------

MyAvatar.propTypes = {
  title: PropTypes.string,
  photoUrl: PropTypes.string
};

export default function MyAvatar({ title, photoUrl, ...other }) {  
  return (
    <Avatar
      src={photoUrl}
      alt={title}
      color={
        photoUrl
          ? 'default'
          : createAvatar(title).color
      }     
      {...other} 
    >
      {createAvatar(title).name}
    </Avatar>
  );
}
