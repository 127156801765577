import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useAtom } from 'jotai';
// routes
import { PATH_DASHBOARD } from '../../shared/routes/paths';
// state
import { userAtom } from '../../shared/state/atoms';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const [user] = useAtom(userAtom);

  if (user) {
    return <Navigate to={PATH_DASHBOARD.general.app} />;
  }

  return <>{children}</>;
}
