import React from 'react';
import firebase from 'firebase';
import { db } from './firebase';
// constants
import { firestoreKeys } from '../utils/constants';

export default function useVacancyService() {
  const [isBusy] = React.useState(false);

  const createVacancy = (data) =>
    db.collection(firestoreKeys.VACANCIES).add({
      ...data,
      meta: {
        createdDate: firebase.firestore.FieldValue.serverTimestamp(),
        modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      },
    });

  const updateVacancy = (vacancyId, data) => db.collection(firestoreKeys.VACANCIES).doc(vacancyId).update(data);

  return { isBusy, createVacancy, updateVacancy };
}
