import { useAesCrypto } from "use-crypto";
import { db } from "./firebase";
import { firestoreKeys } from "../utils/constants";

function useUserService() {
  const { encrypt } = useAesCrypto("&Rw!mx[[s9'L1I-xP<pkHFv4@:O?2g");

  const getUser = (id) =>
    db
      .collection(firestoreKeys.USERS)
      .doc(id)
      .get()
      .then((res) => {
        res.data();
        console.log(res.data());
      });

  const createUser = (userId, formData) => {
    console.log(formData);
    const data = {
      details: {
        firstName: encrypt(formData.firstName),
        lastName: encrypt(formData.lastName),
        idNumber: "",
        dateOfBirth: "",
        employmentStatus: "",
        gender: "",
        hasDriversLicense: "",
        institution: "",
        languages: [],
        location: "",
        nationality: "",
        race: "",
        workPermit: "",
        industry: "",
        photoUrl: "",
        heardAbout: "",
      },
      content: {},
      education: [],
      experience: [],
      skills: [],
      industries: [],
      contact: {
        emailAddress: encrypt(formData.emailAddress),
        contactNumber: "",
      },
      status: "Active",
      role: { position: "", company: "" },
      statistics: { likes: 0, views: 0, interviews: 0 },
      permissions: {},
      interviewUser: false,
      meta: {
        createdDate: new Date(),
        lastActiveDate: new Date(),
      },
    };

    return db
      .collection(firestoreKeys.USERS)
      .doc(userId)
      .set(data)
      .then(() => {
        console.log("User created successfully");
      })
      .catch((error) => {
        console.error("Error creating user:", error);
      });
  };

  const createInterviewUser = (userId, formData) => {
    console.log(formData);
    const data = {
      details: {
        firstName: encrypt(formData.firstName),
        lastName: encrypt(formData.lastName),
        idNumber: "",
        dateOfBirth: "",
        employmentStatus: "",
        gender: "",
        hasDriversLicense: "",
        institution: "",
        languages: [],
        location: "",
        nationality: "",
        race: "",
        workPermit: "",
        industry: "",
        photoUrl: "",
        heardAbout: "",
      },
      content: {},
      education: [],
      experience: [],
      skills: [],
      industries: [],
      contact: {
        emailAddress: encrypt(formData.emailAddress),
        contactNumber: "",
      },
      status: "Active",
      role: { position: "", company: "" },
      statistics: { likes: 0, views: 0, interviews: 0 },
      permissions: {},
      interviewUser: true,
      meta: {
        createdDate: new Date(),
        lastActiveDate: new Date(),
      },
    };

    return db
      .collection(firestoreKeys.USERS)
      .doc(userId)
      .set(data)
      .then(() => {
        console.log("User created successfully");
      })
      .catch((error) => {
        console.error("Error creating user:", error);
      });
  };

  const sendNotification = async (userId, data) => {
    try {
      await db
        .collection(firestoreKeys.USERS)
        .doc(userId)
        .collection(firestoreKeys.NOTIFICATIONS)
        .add(data);
      // Handle the response and data here as needed
    } catch (error) {
      console.error("Error sending notification:", error);
      // Handle the error here
    }
  };

  return { getUser, createUser, createInterviewUser, sendNotification };
}

export default useUserService;
