import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import firebase from "firebase/app";
import { firestoreKeys } from "src/shared/utils/constants";
import "firebase/firestore"; // Import Firestore if not already done
import PremiumVacancyDetailsCard from "./PremiumVacancyDetailsCard";
import PremiumVacancyAboutRoleCard from "./PremiumVacancyAboutRoleCard";

const PremiumJobPost = ({ vacancy }) => {
  const { id } = useParams();
  const [jobPost, setJobPost] = useState(null);
  const [datePosted, setDatePosted] = useState(null);
  const [validThrough, setValidThrough] = useState(null);
  const [company, setCompany] = useState(null);
  const [render, setRender] = useState(false);
  const [html, setHtml] = useState(null);

  useEffect(() => {
    const fetchJobPost = async () => {
      try {
        if (vacancy) {
          setJobPost(vacancy);
          setRender(true);
          return;
        }
        // Fetch the job post using the provided id
        const doc = await firebase
          .firestore()
          .collection("vacancies")
          .doc(id)
          .get();

        if (doc.exists) {
          const job = doc.data();
          const companyId = job.company.id;

          // Fetch company details
          const companyDoc = await firebase
            .firestore()
            .collection(firestoreKeys.COMPANIES)
            .doc(companyId)
            .get();
          const companyData = companyDoc.exists ? companyDoc.data() : null;

          // Format datePosted and validThrough
          const datePosted = new Date(job.meta.createdDate.seconds * 1000);
          const datePostedFormatted = datePosted.toISOString().split("T")[0];

          const validThrough = new Date(job.details.expiryDate.seconds * 1000);
          const validThroughFormatted = validThrough
            .toISOString()
            .split("T")[0];

          // Update state with fetched data
          setJobPost(job);
          setCompany(companyData);
          setDatePosted(datePostedFormatted);
          setValidThrough(validThroughFormatted);
          setRender(true);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }
    };

    fetchJobPost();
  }, [id, vacancy]);

  useEffect(() => {
    const generateHTMLContent = () => {
      if (render === false) {
        return null;
      }
      const jobPostingSchema = jobPost && {
        "@context": "https://schema.org",
        "@type": "JobPosting",
        title: jobPost?.details?.position,
        description: jobPost?.details.description,
        industry: jobPost?.details.industry,
        datePosted: datePosted,
        validThrough: validThrough,
        employmentType: jobPost?.details.employmentType,
        hiringOrganization: {
          "@type": "Organization",
          name: jobPost?.company.name,
        },
        jobLocation: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressRegion1: jobPost?.location?.addressRegion1,
            addressLocality: jobPost?.location?.addressLocality,
            addressRegion: jobPost?.location?.addressRegion,
            addressRegion2: jobPost?.location?.addressRegion2,
            addressCountry: jobPost?.location?.addressCountry,
          },
        },
        baseSalary: {
          "@type": "MonetaryAmount",
          currency: jobPost?.details.currency
            ? jobPost.details.currency
            : "ZAR",
          value: {
            "@type": "QuantitativeValue",
            unitText: "PER MONTH",
            value: jobPost?.details.salary,
          },
        },
      };

      // Render the HTML content
      const htmlContent = `
   <script type="application/ld+json">
     ${JSON.stringify(jobPostingSchema)}
   </script>
  `;

      setHtml(htmlContent);
    };

    // Call generateHTMLContent to set the HTML content
    generateHTMLContent();
  }, [render, jobPost, datePosted, validThrough]);

  // Return the component with both generated HTML content and Typography
  return (
    <>
      {jobPost !== null && (
        <>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <PremiumVacancyDetailsCard vacancy={jobPost} />
          <PremiumVacancyAboutRoleCard vacancy={jobPost} />
        </>
      )}
    </>
  );
};

export default PremiumJobPost;
