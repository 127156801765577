import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useSnackbar } from 'notistack';
import { useAesCrypto } from 'use-crypto';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem } from '@mui/material';
// routes
import { PATH_AUTH } from '../../../../shared/routes/paths';
// services
import { useAuthService, useCompanyUserService } from '../../../../shared/services';
// hooks
import { useToast } from '../../../../shared/hooks';
import useIsMountedRef from '../../../../shared/hooks/useIsMountedRef';
import { userRecordAtom } from '../../../../shared/state/atoms';
// components
import { MyAvatar, MenuPopover } from '../../../../shared/components/base';
import { IconButtonAnimate } from '../../../../shared/components/animate';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const [user] = useAtom(userRecordAtom);
  const { encrypt } = useAesCrypto("&Rw!mx[[s9'L1I-xP<pkHFv4@:O?2g");
  const { showSuccess } = useToast();
  const { signOut } = useAuthService();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(null);
  const inputFile = useRef(null);
  const { updateCompanyUser, addProfileImage } = useCompanyUserService();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const handleFileSelection = async (file) => {
    const downloadUrl = await addProfileImage(user.id, file);
    const updatedDetails = {
      details: {
        companyName: encrypt(user.details.name),
        firstName: encrypt(user.details.firstName),
        lastName: encrypt(user.details.lastName),
        jobTitle: encrypt(user.details.jobTitle),
        location: encrypt(user.details.location),
        photoUrl: downloadUrl,
      },
    };

    await updateCompanyUser(user.id, updatedDetails).then(() => {
      handleClose();
      showSuccess('Succesfully updated profile photo. Please log in again to view the change.');
    });
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar title={user?.details.firstName} photoUrl={user?.details?.photoUrl} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.details?.firstName} {user?.details?.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.contact?.emailAddress}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={onButtonClick} sx={{ m: 1 }}>
          Update Profile Photo
          <input
            type="file"
            id="file"
            ref={inputFile}
            onChange={(event) => handleFileSelection(event.target.files[0])}
            style={{ display: 'none' }}
          />
        </MenuItem>

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
