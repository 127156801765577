import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
// @mui
import {
  Box,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TextField,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

// hooks
import useTable, {
  emptyRows,
  getComparator,
} from "../../shared/hooks/useTable";
// components
import { Scrollbar } from "../../shared/components/base";
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from "../../shared/components/table";

// constants
import { SHARE_ACCESS_COLUMNS } from "../../shared/utils/constants";
import ShareAccessListRow from "./ShareAccessListRow";
import { db } from "src/shared/services/firebase";

// ----------------------------------------------------------------------

ShareAccessListCard.propTypes = {
  interviewId: PropTypes.string,
  responses: PropTypes.array,
};
export default function ShareAccessListCard() {
  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();
  const [tableData, setTableData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [fetchData, setFetchData] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
  });

  const isNotFound = !dataFiltered.length;

  useEffect(() => {
    const fetchAllCompanies = async () => {
      try {
        const companiesSnapshot = await db.collection("companies").get();
        const data = companiesSnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        // Set search data to include only companies that do not have shareAccess enabled
        setSearchData(data.filter((company) => !company.shareAccess));

        // Set table data to include only companies that have shareAccess enabled
        setTableData(data.filter((company) => company.shareAccess));

        setFetchingData(false);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchAllCompanies();
  }, [fetchData]);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredData([]); // Hide results when search is blank
    } else {
      const lowercasedQuery = searchQuery.toLowerCase();
      const filtered = searchData
        .filter((item) =>
          item.contact?.emailAddress?.toLowerCase().includes(lowercasedQuery)
        )
        .slice(0, 5); // Limit to first 5 matching items
      setFilteredData(filtered);
    }
  }, [searchQuery, searchData]);

  const handleFetchData = () => {
    setFetchData((prevState) => !prevState);
  };

  const handleListItemClick = async (id) => {
    setSearchQuery("");
    try {
      await db.collection("companies").doc(id).update({
        shareAccess: true,
      });
      console.log("Document successfully updated!");
      handleFetchData(); // Refresh data
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  return (
    <Box sx={{ textAlign: "center", marginTop: "50px" }}>
      <TextField
        sx={{ maxWidth: "80%", margin: "auto" }}
        label="Search by Email"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginBottom: "20px" }}
      />
      <List fullWidth sx={{ maxWidth: "80%", margin: "auto" }}>
        {filteredData.map((item) => (
          <ListItem
            key={item.id}
            button
            onClick={() => handleListItemClick(item.id)}
          >
            <ListItemText
              primary={item.contact.emailAddress}
              secondary={item.details.name}
            />
          </ListItem>
        ))}
      </List>
      <Card sx={{ maxWidth: "80%", margin: "auto" }}>
        <CardHeader title={"Sharing Access"} />
        <Scrollbar sx={{ mt: 2 }}>
          {fetchingData ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer sx={{ minWidth: 800, position: "relative" }}>
              <Table size="medium">
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={SHARE_ACCESS_COLUMNS}
                  rowCount={tableData.length}
                  onSort={onSort}
                />

                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <ShareAccessListRow
                        key={row.id}
                        row={row}
                        onFetchData={handleFetchData}
                      />
                    ))}

                  {isNotFound && (
                    <TableEmptyRows
                      height={72}
                      emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                    />
                  )}

                  {isNotFound && !fetchingData && (
                    <TableNoData isNotFound={isNotFound} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Scrollbar>
        <Box sx={{ position: "relative" }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dataFiltered.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </Box>
      </Card>
    </Box>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}
