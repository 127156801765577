import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const isDarkModeAtom = atom(false);
export const headerMenuKeyAtom = atom('dashboard');
export const menuDrawerOpenAtom = atom(false);

export const userAtom = atomWithStorage('user', null);
export const userRecordAtom = atomWithStorage('userRecord', null);

export const showToastAtom = atom(false);
export const toastAtom = atom({ severity: 'info', duration: 0, message: '' });

export const searchFilterTextAtom = atom('');
export const searchCountryAtom = atom('All');
export const searchProvinceAtom = atom('All');
export const searchFilterRaceAtom = atom('All');
export const searchFilterGenderAtom = atom('All');
export const searchFilterEmploymentStatusAtom = atom('All');
export const searchFilterLanguageAtom = atom('All');
export const searchFilterDrivesLicenseAtom = atom('All');
export const searchFilterAgeGroupAtom = atom('All');
export const searchFilterIndustryAtom = atom('All');
export const searchFilterJobTitleAtom = atom('All');
