import { useNavigate } from 'react-router';
import { useAtom } from 'jotai';
// routes
import { PATH_DASHBOARD } from '../../../../shared/routes/paths';
// state
import { userRecordAtom } from '../../../../shared/state/atoms';
// hooks
import { useMapper } from '../../../../shared/hooks';
// services
import { useAuthService, useCompanyUserService } from '../../../../shared/services';

function useLoginForm() {
  const navigate = useNavigate();
  const [, setUserRecord] = useAtom(userRecordAtom);
  const { mapCompanyUser } = useMapper();
  const { signIn } = useAuthService();
  const { getUser } = useCompanyUserService();

  const login = (email, password) =>
    signIn(email, password).then((res) => {
      getUser(res.user.uid).then((user) => {        
        const mappedUser = mapCompanyUser({ ...user, id: res.user.uid });
        setUserRecord(mappedUser);
        navigate(PATH_DASHBOARD.general.app, { replace: true });
      });
      //   .catch((error) => showError(error.message));
    });
  //   .catch(() => showError('The email or password that you have entered is incorrect.'))

  return { login };
}

export default useLoginForm;
