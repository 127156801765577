import PropTypes from 'prop-types';
// @mui
import { Card, CardHeader, TextField, Grid, Button } from '@mui/material';
import { useState } from 'react';

// ----------------------------------------------------------------------

InterviewResponseFeedbackCard.propTypes = {
  previousFeedback: PropTypes.string,
  disabled: PropTypes.bool,
  handleResponseUpdate: PropTypes.func,
};

export default function InterviewResponseFeedbackCard({ previousFeedback, disabled, handleResponseUpdate }) {
  const [feedback, setFeedback] = useState(previousFeedback);

  return (
    <Card sx={{ mt: 3, mb: 3 }}>
      <CardHeader title="Feedback" />

      <TextField
        disabled={disabled}
        sx={{ p: 2 }}
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        placeholder="Type any feedback here..."
        fullWidth
        multiline
        rows={5}
      />

      {!disabled && (
        <Grid container spacing={5} sx={{ p: 3 }}>
          <Grid item sm={6} md={6}>
            <Button variant="contained" fullWidth onClick={() => handleResponseUpdate('Approved', feedback)}>
              Approve
            </Button>
          </Grid>
          <Grid item sm={6} md={6}>
            <Button variant="outlined" fullWidth onClick={() => handleResponseUpdate('Declined', feedback)}>
              Decline
            </Button>
          </Grid>
        </Grid>
      )}
    </Card>
  );
}
