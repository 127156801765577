import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import {
  Card,
  Typography,
  CardHeader,
  Stack,
  Grid,
  Button,
} from "@mui/material";
// components
import { Iconify } from "src/shared/components/base";
// utils
import { formatTimestampToDate } from "src/shared/utils";

// ----------------------------------------------------------------------

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 30,
  height: 30,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

PremiumVacancyDetailsCard.propTypes = {
  vacancy: PropTypes.object,
};

export default function PremiumVacancyDetailsCard({ vacancy }) {
  const { company, details, meta, postedBy } = vacancy;

  function navigateToExternalSite() {
    let url = "https://pik-uniq-prod.web.app/";
    window.open(url, "_blank");
  }

  return (
    <Card sx={{ mt: 3, ml: 5, mr: 5 }}>
      <CardHeader
        sx={{ mt: 3, ml: 3, mr: 3 }}
        title={`Job Details For ${details?.position}`}
      />

      <Grid container>
        <Grid item sx={6} md={6}>
          <Stack spacing={2} sx={{ p: 5 }}>
            {details?.currency ? (
              <Stack direction="row">
                <CurrencyExchangeIcon
                  sx={{
                    width: 20,
                    height: 20,
                    flexShrink: 0,
                    marginRight: 2,
                  }}
                />
                <Typography variant="body2">{details.currency}</Typography>
              </Stack>
            ) : null}
            <Stack direction="row">
              <IconStyle icon={"eva:pricetags-outline"} />
              <Typography sx={{ mt: 1 }} variant="body1">
                {details?.salary}
              </Typography>
            </Stack>

            <Stack direction="row">
              <IconStyle icon={"eva:pin-outline"} />
              <Typography sx={{ mt: 1 }} variant="body1">
                {typeof details?.location === "string"
                  ? details.location
                  : details?.location?.addressLocality}
              </Typography>
            </Stack>

            <Stack direction="row">
              <IconStyle icon={"carbon:industry"} />
              <Typography sx={{ mt: 1 }} variant="body1">
                {details?.industry}
              </Typography>
            </Stack>

            <Stack direction="row">
              <IconStyle icon={"eva:briefcase-outline"} />
              <Typography sx={{ mt: 1 }} variant="body1">
                {company?.industry}
              </Typography>
            </Stack>
            <Stack direction="row">
              <IconStyle icon={"eva:home-outline"} />
              <Typography sx={{ mt: 1 }} variant="body1">
                {details?.workingScenario}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item sx={6} md={6}>
          <Stack spacing={2} sx={{ p: 3 }}>
            <Stack direction="row">
              <IconStyle icon={"eva:search-outline"} />
              <Typography sx={{ mt: 1 }} variant="body1">
                {details?.employmentType}
              </Typography>
            </Stack>
            <Stack direction="row">
              <IconStyle icon={"eva:calendar-outline"} />
              <Typography sx={{ mt: 1 }} variant="body1">
                Posted Date: &nbsp;
              </Typography>
              <Typography sx={{ mt: 1 }} variant="body1">
                {formatTimestampToDate(meta?.createdDate)}
              </Typography>
            </Stack>

            <Stack direction="row">
              <IconStyle icon={"eva:calendar-fill"} />
              <Typography variant="body1">Expiry Date: &nbsp;</Typography>
              <Typography variant="body1">
                {formatTimestampToDate(details?.expiryDate)}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          className="button"
          variant="contained"
          onClick={navigateToExternalSite}
          sx={{ m: 2, height: 60, width: 120 }}
        >
          <Typography variant="button" sx={{ fontSize: 18 }}>
            Apply
          </Typography>
        </Button>
      </div>
    </Card>
  );
}
