import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Typography, CardHeader, Stack, Grid } from '@mui/material';
// components
import { Iconify } from 'src/shared/components/base';

// ----------------------------------------------------------------------

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

PremiumVacancyAboutRoleCard.propTypes = {
  vacancy: PropTypes.object
}

export default function PremiumVacancyAboutRoleCard({ vacancy }) {
  const { details } = vacancy;

  return (
    <Card sx={{ mt: 3, ml: 5, mr: 5 }}>
      <CardHeader title="About The Role" />
      <Grid container>
        <Stack spacing={2} sx={{ p: 3 }}>
          <Stack direction="row">
            <IconStyle icon={'eva:award-outline'} />
            <Typography variant="body3">{details?.skills}</Typography>
          </Stack>

          <Stack direction="row">
            <IconStyle icon={'eva:info-outline'} />
            <Typography variant="body3">{details?.description}</Typography>
          </Stack>
        </Stack>
      </Grid>
    </Card>
  )
}

