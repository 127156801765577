import React from "react";
import firebase from "firebase";
//import * as admin from "firebase-admin";
import { useAtom } from "jotai";
import { auth } from "./firebase";
import { userAtom, userRecordAtom } from "../state/atoms";
import { firebaseErrorCodes } from "../utils/constants";

// admin.initializeApp();

function useAuthService() {
  const [, setUser] = useAtom(userAtom);
  const [, setUserRecord] = useAtom(userRecordAtom);

  React.useEffect(
    () => auth().onAuthStateChanged((user) => setUser(user)),
    [setUser]
  );

  const signIn = (email, password) =>
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      // eslint-disable-next-line arrow-body-style
      .then(() => {
        return auth()
          .signInWithEmailAndPassword(email, password)
          .then((res) => res);
      });

  function signUp(email, password) {
    return auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        switch (err.code) {
          case firebaseErrorCodes.EMAIL_IN_USER:
            break;
          default:
            break;
        }
      });
  }

  const checkUser = async (email) => {
    const checkUserCallable = firebase
      .functions()
      .httpsCallable("externalInterviewUser");

    try {
      const result = await checkUserCallable({ email });
      return result.data;
    } catch (error) {
      throw error;
    }
  };

  const deleteSub = async (uid) => {
    const deleteSubUser = firebase.functions().httpsCallable("deleteSubUser");

    try {
      const result = await deleteSubUser({ uid });
      return result.data;
    } catch (error) {
      throw error;
    }
  };

  const resetPassword = (email) =>
    auth()
      .sendPasswordResetEmail(email)
      .then((res) => res);

  function signOut() {
    setUser(null);
    setUserRecord(null);
    return auth().signOut();
  }

  const sendToIndexingAPI = async (vacancyId) => {
    const postToIndexingAPI = firebase
      .functions()
      .httpsCallable("postToIndexingAPI");
    try {
      const result = await postToIndexingAPI({ vacancyId });
      return result.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    signIn,
    signUp,
    checkUser,
    deleteSub,
    resetPassword,
    signOut,
    sendToIndexingAPI,
  };
}

export default useAuthService;
