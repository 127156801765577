import { useAesCrypto } from "use-crypto";
import { calculateAgeGroup } from "../utils";

function useMapper() {
  const { decrypt } = useAesCrypto("&Rw!mx[[s9'L1I-xP<pkHFv4@:O?2g");

  const mapUser = (user) => ({
    ...user,
    contact: {
      ...user?.contact,
      emailAddress: decrypt(user?.contact?.emailAddress ?? ""),
      contactNumber: decrypt(user?.contact?.contactNumber ?? ""),
    },
    role: {
      ...user?.role,
      position: decrypt(user?.role?.position ?? ""),
      company: decrypt(user?.role?.company ?? ""),
    },
    details: {
      ...user?.details,
      firstName: decrypt(user?.details?.firstName ?? ""),
      lastName: decrypt(user?.details?.lastName ?? ""),
      location: decrypt(user?.details?.location ?? ""),
      dateOfBirth: decrypt(user?.details?.dateOfBirth ?? ""),
      hasDriversLicense: decrypt(user?.details?.hasDriversLicense ?? ""),
      employmentStatus: decrypt(user?.details?.employmentStatus ?? ""),
      gender: decrypt(user?.details?.gender ?? ""),
      industry: decrypt(user?.details?.industry ?? ""),
      nationality: decrypt(user?.details?.nationality ?? ""),
      province: decrypt(user?.details?.province ?? ""),
      race: decrypt(user?.details?.race ?? ""),
      workPermit: decrypt(user?.details?.workPermit ?? ""),
      ageGroup: calculateAgeGroup(
        decrypt(user?.details?.dateOfBirth ?? "") ?? ""
      ),
    },
  });

  const mapCompanyUser = (user) => ({
    ...user,
    contact: {
      ...user?.contact,
      emailAddress: decrypt(user?.contact?.emailAddress ?? ""),
      contactNumber: decrypt(user?.contact?.contactNumber ?? ""),
    },
    details: {
      ...user?.details,
      companyName: decrypt(user?.details?.companyName ?? ""),
      firstName: decrypt(user?.details?.firstName ?? ""),
      lastName: decrypt(user?.details?.lastName ?? ""),
      jobTitle: decrypt(user?.details?.jobTitle ?? ""),
      location: decrypt(user?.details?.location ?? ""),
    },
  });

  const mapCompany = (company) => {
  

    const decryptedDetails = {
      ...company?.details,
      regNumber: decryptCompanyWithFallback(company?.details?.regNumber ?? ""),
      taxNumber: decryptCompanyWithFallback(company?.details?.taxNumber ?? ""),
    };

    // Return the company object with decrypted details
    return {
      ...company,
      details: decryptedDetails,
    };
  };

  const decryptCompanyWithFallback = (encryptedValue) => {
    try {
      return decrypt(encryptedValue);
    } catch (error) {
      console.warn(
        `Failed to decrypt value: ${encryptedValue}. Using original value.`
      );
      return encryptedValue; // Fallback to the original value if decryption fails
    }
  };

  const mapVacancyResponse = (response) => ({
    ...response,
    company: decrypt(response?.company ?? ""),
    location: decrypt(response?.location ?? ""),
    position: decrypt(response?.position ?? ""),
  });

  const mapInterviewResponse = (response) => ({
    ...response,
    user: {
      ...response?.user,
      firstName: decrypt(response?.user?.firstName ?? ""),
      lastName: decrypt(response?.user?.lastName ?? ""),
      emailAddress: decrypt(response?.user?.emailAddress ?? ""),
      location: decrypt(response?.user?.location ?? ""),
      company: decrypt(response?.user?.company ?? ""),
      position: decrypt(response?.user?.position ?? ""),
    },
  });

  const mapPurchase = (purchase) => ({
    ...purchase,
    user: {
      ...purchase?.user,
      firstName: decrypt(purchase?.user?.firstName ?? ""),
      lastName: decrypt(purchase?.user?.lastName ?? ""),
      emailAddress: decrypt(purchase?.user?.emailAddress ?? ""),
      location: decrypt(purchase?.user?.location ?? ""),
      jobTitle: decrypt(purchase?.user?.jobTitle ?? ""),
    },
  });

  const mapShortlist = (item) => ({
    ...item,
    user: {
      ...item?.user,
      emailAddress: decrypt(item?.user?.emailAddress ?? ""),
      firstName: decrypt(item?.user?.firstName ?? ""),
      lastName: decrypt(item?.user?.lastName ?? ""),
      location: decrypt(item?.user?.location ?? ""),
      jobTitle: decrypt(item?.user?.jobTitle ?? ""),
    },
  });

  return {
    mapUser,
    mapCompanyUser,
    mapCompany,
    mapShortlist,
    mapVacancyResponse,
    mapInterviewResponse,
    mapPurchase,
  };
}

export default useMapper;
