import firebase from 'firebase';
import React from 'react';
import { db } from './firebase';
import { firestoreKeys } from '../utils/constants';

function usePurchaseService() {
  const [isBusy] = React.useState(false);

  const createPurchase = (data) => db.collection(firestoreKeys.PURCHASES).add({
      ...data,
      meta: {
        createdDate: firebase.firestore.FieldValue.serverTimestamp(),
        modifiedDate: firebase.firestore.FieldValue.serverTimestamp()
      }
    });
  

  return { isBusy, createPurchase };
}

export default usePurchaseService;
