// routes
import Router from "../shared/routes";
import React from "react";
// theme
import ThemeProvider from "../theme";
// components
import ThemeSettings from "../shared/components/settings";
import { ChartStyle } from "../shared/components/chart";
import {
  ScrollToTop,
  NotistackProvider,
  ProgressBarStyle,
  Toast,
} from "../shared/components/base";
import MotionLazyContainer from "../shared/components/animate/MotionLazyContainer";
import "../shared/styles/global.css";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
            <Toast />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
