import { useState } from 'react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Typography, RadioGroup, Grid, CardActionArea } from '@mui/material';
// hooks
import { useSettings } from '../../../../shared/hooks';
// components
import { IconButtonAnimate } from '../../../../shared/components/animate';
import { Iconify, MenuPopover } from '../../../../shared/components/base';
import BoxMask from './BoxMask';

// ----------------------------------------------------------------------

const BoxStyle = styled(CardActionArea)(({ theme }) => ({
  height: 72,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.disabled,
  border: `solid 1px ${theme.palette.grey[500_12]}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.25,
}));

// ----------------------------------------------------------------------

export default function ThemePopover() {
  const [open, setOpen] = useState(null);
  const { themeMode, onChangeMode } = useSettings();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify icon={'eva:options-2-fill'} width={20} height={20} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 320,
          '& .MuiMenuItem-root': {
            px: 1.5,
            height: 72,
            borderRadius: 0.75,
          },
        }}
      >
        <Typography variant="h6" sx={{ p: 1.5 }}>
          Settings
        </Typography>

        <RadioGroup name="themeMode" value={themeMode} onChange={onChangeMode}>
          <Grid dir="ltr" container spacing={2.5}>
            {['light', 'dark'].map((mode, index) => {
              const isSelected = themeMode === mode;

              return (
                <Grid key={mode} item xs={6}>
                  <BoxStyle
                    sx={{
                      bgcolor: mode === 'light' ? 'common.white' : 'grey.800',
                      ...(isSelected && {
                        color: 'primary.main',
                        boxShadow: (theme) => theme.customShadows.z20,
                      }),
                    }}
                  >
                    <Iconify icon={index === 0 ? 'ph:sun-duotone' : 'ph:moon-duotone'} width={28} height={28} />
                    <BoxMask value={mode} />
                  </BoxStyle>
                </Grid>
              );
            })}
          </Grid>
        </RadioGroup>
      </MenuPopover>
    </>
  );
}
