import firebase from 'firebase';
import { db, storage } from './firebase';
import { firestoreKeys } from '../utils/constants';

function useCompanyUserService() {
  const getUser = (id) =>
    db
      .collection(firestoreKeys.COMPANY_USERS)
      .doc(id)
      .get()
      .then((res) => res.data());

  const addProfileImage = (docId, file) => {
    const ref = storage.ref(`companyUsers/${docId}/${file.name}`);
    return ref.put(file).then(() => ref.getDownloadURL());
  };

  const createUser = (id, user) =>
    db
      .collection(firestoreKeys.COMPANY_USERS)
      .doc(id)
      .set({
        ...user,
        meta: {
          createdDate: firebase.firestore.FieldValue.serverTimestamp(),
          modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
          lastActiveDate: firebase.firestore.FieldValue.serverTimestamp(),
        },
      });

  const updateCompanyUser = (userId, data) => db.collection(firestoreKeys.COMPANY_USERS).doc(userId).update(data);

  const updateLastActiveDate = async (userId) => {
    const sfDocRef = db.collection(firestoreKeys.COMPANY_USERS).doc(userId);

    return db
      .runTransaction((transaction) =>
        transaction.get(sfDocRef).then((sfDoc) => {
          const meta = { ...sfDoc.data().meta, lastActiveDate: firebase.firestore.FieldValue.serverTimestamp() };
          transaction.update(sfDocRef, { meta });
        })
      )
      .catch((error) => {
        console.log('Transaction failed: ', error);
      });
  };

  const incrementActiveJobPostStatistic = async (userId) => {
    const sfDocRef = db.collection(firestoreKeys.COMPANY_USERS).doc(userId);

    return db
      .runTransaction((transaction) =>
        transaction.get(sfDocRef).then((sfDoc) => {
          const statistics = { ...sfDoc.data().statistics, jobPostsActive: sfDoc.data().statistics.jobPostsActive + 1 };
          transaction.update(sfDocRef, { statistics });
        })
      )
      .catch((error) => {
        console.log('Transaction failed: ', error);
      });
  };

  const decrementActiveJobPostStatistic = async (userId) => {
    const sfDocRef = db.collection(firestoreKeys.COMPANY_USERS).doc(userId);

    return db
      .runTransaction((transaction) =>
        transaction.get(sfDocRef).then((sfDoc) => {
          const statistics = { ...sfDoc.data().statistics, jobPostsActive: sfDoc.data().statistics.jobPostsActive - 1 };
          transaction.update(sfDocRef, { statistics });
        })
      )
      .catch((error) => {
        console.log('Transaction failed: ', error);
      });
  };

  const incrementOdisPurchasedtStatistic = async (userId) => {
    const sfDocRef = db.collection(firestoreKeys.COMPANY_USERS).doc(userId);

    return db
      .runTransaction((transaction) =>
        transaction.get(sfDocRef).then((sfDoc) => {
          const statistics = { ...sfDoc.data().statistics, odisPurchased: sfDoc.data().statistics.odisPurchased + 1 };
          transaction.update(sfDocRef, { statistics });
        })
      )
      .catch((error) => {
        console.log('Transaction failed: ', error);
      });
  };

  const incrementVideoResumesPurchasedStatistic = async (userId) => {
    const sfDocRef = db.collection(firestoreKeys.COMPANY_USERS).doc(userId);

    return db
      .runTransaction((transaction) =>
        transaction.get(sfDocRef).then((sfDoc) => {
          const statistics = {
            ...sfDoc.data().statistics,
            videoResumesPurchased: sfDoc.data().statistics.videoResumesPurchased + 1,
          };
          transaction.update(sfDocRef, { statistics });
        })
      )
      .catch((error) => {
        console.log('Transaction failed: ', error);
      });
  };

  return {
    getUser,
    createUser,
    updateCompanyUser,
    addProfileImage,
    updateLastActiveDate,
    incrementActiveJobPostStatistic,
    decrementActiveJobPostStatistic,
    incrementOdisPurchasedtStatistic,
    incrementVideoResumesPurchasedStatistic,
  };
}

export default useCompanyUserService;
