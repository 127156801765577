import React from 'react';
import { useAtom } from 'jotai';
// components
import { Alert, Snackbar } from '@mui/material';
// state
import { showToastAtom, toastAtom } from '../../state/atoms';

export default function Toast() {
  const [showToast, setShowToast] = useAtom(showToastAtom);
  const [toast] = useAtom(toastAtom);

  return (
    <Snackbar open={showToast} autoHideDuration={toast.duration} onClose={() => setShowToast(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <Alert severity={toast.severity} sx={{ width: '100%' }}>
        {toast.message}
      </Alert>
    </Snackbar>
  );
}
