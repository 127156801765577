import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Grid, Box, Link, Container, Typography, Stack } from '@mui/material';
// components
import { Logo } from '../../../shared/components/base';
import MainFooter from './MainFooter';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  const isHome = pathname === '/';

  return (
    <Stack sx={{ minHeight: 1 }}>
      <Outlet />
      <Box sx={{ flexGrow: 1 }} />

      {!isHome ? (
        <MainFooter />
      ) : (
        <Box
          sx={{
            py: 5,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default',
          }}
        >
          <Container>
            <Grid container>
              <Grid item xs={4} md={4}>
                {' '}
                <Link href="https://firebasestorage.googleapis.com/v0/b/pik-uniq-prod.appspot.com/o/termsOfService%2FPikUniq_Terms%20of%20Service_Website.pdf?alt=media&token=aac91fb2-8fe8-437e-b42e-252bfe75b018">
                  Terms of Service
                </Link>
              </Grid>
              <Grid item xs={4} md={4}>
                <Logo sx={{ mb: 1, mx: 'auto' }} />

                <Typography variant="caption" component="p">
                  Copyright © 2022 PikUniq (Pty) Ltd. 
                  <br />
                  All rights reserved.
                  <br /> Built by&nbsp;
                  <Link href="https://www.origensoftware.com">Origen Software</Link>
                </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Link href="https://firebasestorage.googleapis.com/v0/b/pik-uniq-prod.appspot.com/o/privacyPolicy%2FPikUniq_Privacy%20Policy_Website.pdf?alt=media&token=b31c689c-ef18-447d-9483-17b7f5b0fd01">
                  Privacy Policy
                </Link>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </Stack>
  );
}
