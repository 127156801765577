import { db } from './firebase';
import { firestoreKeys } from '../utils/constants';

function useMailService() {
  
  const sendEmail = (data) => db.collection(firestoreKeys.MAIL).add(data);

  return { sendEmail };
}

export default useMailService;
