import React from "react";
import firebase from "firebase";
import { db, storage } from "./firebase";
import { firestoreKeys } from "../utils/constants";

function useCompanyService() {
  const [isBusy] = React.useState(false);

  const createCompany = () =>
    db.collection(firestoreKeys.COMPANIES).add({
      meta: {
        createdDate: firebase.firestore.FieldValue.serverTimestamp(),
        modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      },
    });

  const updateCompany = (companyId, data) =>
    db.collection(firestoreKeys.COMPANIES).doc(companyId).update(data);

  const addProfileImage = (docId, image) => {
    const ref = storage.ref(`companies/${docId}/${image.name}`);
    return ref.put(image).then(() => ref.getDownloadURL());
  };

  const addCredits = (id, current, amount) =>
    db
      .collection(firestoreKeys.COMPANIES)
      .doc(id)
      .update({
        credits: Number(current) + Number(amount),
      });

  const deductCredits = (id, current, amount) =>
    db
      .collection(firestoreKeys.COMPANIES)
      .doc(id)
      .update({
        credits: current - amount,
      });

  const deductUserTokens = (id, current, amount) =>
    db
      .collection(firestoreKeys.COMPANIES)
      .doc(id)
      .update({
        candidateTokens: current - amount,
      });

  const deductInterviewTokens = (id, current, amount) =>
    db
      .collection(firestoreKeys.COMPANIES)
      .doc(id)
      .update({
        oneWayInterviewTokens: current - amount,
      });

  const deductJobTokens = (id, current, amount) =>
    db
      .collection(firestoreKeys.COMPANIES)
      .doc(id)
      .update({
        jobTokens: current - amount,
      });

  return {
    isBusy,
    createCompany,
    updateCompany,
    addProfileImage,
    addCredits,
    deductCredits,
    deductUserTokens,
    deductInterviewTokens,
    deductJobTokens,
  };
}

export default useCompanyService;
