import React from "react";
import firebase from "firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
// hooks
import { useMapper, useToast } from "../../../../shared/hooks";
// services
import {
  useInterviewService,
  useMailService,
  useUserService,
} from "../../../../shared/services";
// constants
import { firestoreKeys } from "../../../../shared/utils/constants";

export default function useInterviewResponseDetails(interviewId, responseId) {
  const { updateInterviewResponse } = useInterviewService();
  const [response, setResponse] = React.useState();
  const { showSuccess, showError } = useToast();
  const { mapInterviewResponse } = useMapper();
  const { sendNotification } = useUserService();
  const { sendEmail } = useMailService();
  const [interview] = useDocumentData(
    firebase.firestore().collection(firestoreKeys.INTERVIEWS).doc(interviewId),
    {
      idField: "id",
    }
  );

  const [responseData] = useDocumentData(
    firebase
      .firestore()
      .collection(firestoreKeys.INTERVIEWS)
      .doc(interviewId)
      .collection(firestoreKeys.RESPONSES)
      .doc(responseId)
  );

  React.useEffect(() => {
    if (responseData) {
      setResponse(mapInterviewResponse(responseData));
    }
  }, [responseData]);

  const updateResponseStatus = (response, status, feedback) =>
    updateInterviewResponse(interviewId, responseId, { status, feedback })
      .then(() =>
        showSuccess("Successfully updated status and submitted feedback.")
      )
      .catch(() => showError("Failed to updated status and submit feedback."))
      .finally(() => {
        sendEmail({
          message: {
            subject: `PikUniq - Interview Feedback from ${interview.company.name}`,
            text: "PikUniq Interview Feedback",
            html: `
          <!doctype html>
        <html lang="en-US">
        
        <head>
            <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
            <title>PikUniq Interview Feedback</title>
            <meta name="description" content="PikUniq Interview Feedback">
            <style type="text/css">
                a:hover {text-decoration: underline !important;}
            </style>
        </head>
        
        <body marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
            <!--100% body table-->
            <table cellspacing="0" border="0" cellpadding="0" width="100%" bgcolor="#f2f3f8"
                style="@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700|Open+Sans:300,400,600,700); font-family: 'Open Sans', sans-serif;">
                <tr>
                    <td>
                        <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
                            align="center" cellpadding="0" cellspacing="0">
                            <tr>
                                <td style="height:80px;">&nbsp;</td>
                            </tr>
                            <tr>
                                <td style="text-align:center;">
                                  <a href="https://www.pikuniq.com" title="logo" target="_blank">
                                    <img width="450" src="https://play-lh.googleusercontent.com/mPxWz2tpcA8JO7nr6GK-nkBWlEd2-1gFKgLupuMGwg5QufOrOh-t4VV15MbuHQ8mfJo" title="logo" alt="logo">
                                  </a>
                                </td>
                            </tr>
                            <tr>
                                <td style="height:20px;">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>
                                    <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                        style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                        <tr>
                                            <td style="height:40px;">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style="padding:0 35px;">
                                                <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">You have feedback from your recent ODI</h1>
                                                <span
                                                    style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                                                    <p>Position: <strong>${interview.details.position}</strong>
                                                    <p>Company: <strong>${interview.company.name}</strong>
                                                   	<p>Status: <strong>${status}</strong>                    
                                                    <p><string>"${feedback}"</string></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="height:40px;">&nbsp;</td>
                                        </tr>
                                    </table>
                                </td>
                            <tr>
                                <td style="height:20px;">&nbsp;</td>
                            </tr>
                            <tr>
                                <td style="text-align:center;">
                                    <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>www.pikuniq.com</strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td style="height:80px;">&nbsp;</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
            <!--/100% body table-->
        </body>
        
        </html>`,
          },
          to: [response.user.emailAddress],
        });

        sendNotification(response.user.id, {
          type: "feedback",
          text: "You have feedback on your ODI responses.",
          token: response.user.token,
          meta: {
            createdDate: firebase.firestore.FieldValue.serverTimestamp(),
            modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
          },
        });
      });

  return { response, updateResponseStatus };
}
