import { Suspense, lazy, useContext, useState, useEffect } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import { DataContext } from "src/app/DataContext";
import firebase from "firebase";
import { firestoreKeys } from "src/shared/utils/constants";
// layouts
import DashboardLayout from "../../app/layouts/dashboard";
import MainLayout from "../../app/layouts/main";
import LogoOnlyLayout from "../../app/layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../../app/guards/GuestGuard";
import AuthGuard from "../../app/guards/AuthGuard";
import { PATH_AFTER_LOGIN } from "../../app/config";
// components
import { LoadingScreen } from "../components/base";
import TempInterviewResponseDetails from "./TempInterviewResponseDetails";
import PremiumJobPost from "../../modules/googleJobIntergration/PremiumJobs";
import SitemapFetcher from "./sitemap";
import ShareAccessListCard from "src/modules/sharingAccess/ShareAccess";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const contextData = useContext(DataContext);
  const [vacancyList, setVacancyList] = useState(contextData || []);

  useEffect(() => {
    if (!contextData) {
      const fetchData = async () => {
        const snapshot = await firebase
          .firestore()
          .collection(firestoreKeys.VACANCIES)
          .where("status", "==", "Active")
          .get();
        const newData = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          .filter(
            (vacancy) =>
              typeof vacancy.details.location === "object" &&
              !Object.values(vacancy.details.location).some(
                (value) => value === ""
              ) &&
              vacancy.details.rateType
          );
        newData.sort((a, b) => b.meta.createdDate - a.meta.createdDate);
        setVacancyList(newData);
      };

      fetchData();
    }
  }, [contextData]);

  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "reset-password", element: <ResetPassword /> },
      ],
    },
    {
      path: "privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "competition",
      element: <Competition />,
    },

    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "app", element: <Dashboard /> },
      ],
    },
    // Shortlist Routes
    {
      path: "search",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/search" replace />, index: true },
        { path: "list", element: <Search /> },
      ],
    },
    // Shortlist Routes
    {
      path: "shortlist",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/shortlist" replace />, index: true },
        { path: "list", element: <Shortlist /> },
      ],
    },
    // Shortlist Routes
    {
      path: "purchases",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/purchases" replace />, index: true },
        { path: "list", element: <PurchaseList /> },
      ],
    },
    // Account Details Routes
    {
      path: "account-details",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/account-details" replace />, index: true },
        { path: "profile", element: <AccountDetails /> },
      ],
    },
    {
      path: "temporary-access",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/temporary-access" replace />, index: true },
        { path: "table", element: <TemporaryInterviewList /> },
      ],
    },
    {
      path: "share-access",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/share-access" replace />, index: true },
        { path: "table", element: <ShareAccessListCard /> },
      ],
    },
    // User Routes
    {
      path: "user",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/user" replace />, index: true },
        { path: ":id/profile", element: <UserDetails /> },
      ],
    },
    // Vacancy Routes
    {
      path: "vacancies",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/vacancies" replace />, index: true },
        { path: "list", element: <VacancyList /> },
        { path: ":id/profile", element: <VacancyDetails /> },
      ],
    },
    // Interview Routes
    {
      path: "interviews",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/interviews" replace />, index: true },
        { path: "list", element: <InterviewList /> },
        { path: ":id/profile", element: <InterviewDetails /> },
        {
          path: ":interviewId/profile/response/:responseId",
          element: <InterviewResponseDetails />,
        },
      ],
    },
    // Company User Routes
    {
      path: "company-user",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/company-user" replace />, index: true },
        { path: ":id/profile", element: <CompanyUserDetails /> },
      ],
    },
    // Temp Interview Routes
    {
      path: "/temp-interview/:interviewId/profile/response/:responseId",
      element: <TempInterviewResponseDetails />,
    },

    {
      path: "/jobs/:id",
      element: <PremiumJobPost />,
    },
    {
      path: "/sitemap",
      element: <SitemapFetcher />,
    },
    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { element: <Landing />, index: true },
        { path: "dashboard/app", element: <Dashboard /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// LANDING
const Landing = Loadable(lazy(() => import("../../modules/landing/Landing")));

// DOCUMENTATION
const PrivacyPolicy = Loadable(
  lazy(() => import("../../modules/documentation/PrivacyPolicy"))
);
const Competition = Loadable(
  lazy(() => import("../../modules/documentation/Competition"))
);

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../../modules/auth/Login")));
const Register = Loadable(lazy(() => import("../../modules/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../../modules/auth/ResetPassword"))
);

// GENERAL
const Dashboard = Loadable(
  lazy(() => import("../../modules/dashboard/Dashboard"))
);

// SEARCH
const Search = Loadable(lazy(() => import("../../modules/search/Search")));

// INTERVIEWS
const InterviewList = Loadable(
  lazy(() => import("../../modules/interviews/InterviewList"))
);
const InterviewDetails = Loadable(
  lazy(() => import("../../modules/interviews/InterviewDetails"))
);
const InterviewResponseDetails = Loadable(
  lazy(() => import("../../modules/interviews/InterviewResponseDetails"))
);

const TemporaryInterviewList = Loadable(
  lazy(() =>
    import(
      "../../modules/interviews/InterviewDetails/components/TemporaryInterview"
    )
  )
);

// VAVANCIES
const VacancyList = Loadable(
  lazy(() => import("../../modules/vacancies/VacancyList"))
);
const VacancyDetails = Loadable(
  lazy(() => import("../../modules/vacancies/VacancyDetails"))
);

// SHORTLIST
const Shortlist = Loadable(
  lazy(() => import("../../modules/shortlist/Shortlist"))
);

// PURCHASES
const PurchaseList = Loadable(
  lazy(() => import("../../modules/purchases/PurchaseList"))
);

// ACCOUNT DETAILS
const AccountDetails = Loadable(
  lazy(() => import("../../modules/account/AccountDetails"))
);

// USER
const UserDetails = Loadable(
  lazy(() => import("../../modules/users/UserDetails"))
);

// COMPANY USER
const CompanyUserDetails = Loadable(
  lazy(() => import("../../modules/company-users/CompanyUserDetails"))
);

// MAIN
const Page500 = Loadable(lazy(() => import("../../modules/auth/Page500")));
const Page403 = Loadable(lazy(() => import("../../modules/auth/Page403")));
const Page404 = Loadable(lazy(() => import("../../modules/auth/Page404")));
