import { db } from './firebase';
import { firestoreKeys } from '../utils/constants';

function useNotificationService() {
  const markNotificationAsRead = async (userId, notificationId) => {
    const sfDocRef = db
      .collection(firestoreKeys.COMPANY_USERS)
      .doc(userId)
      .collection(firestoreKeys.NOTIFICATIONS)
      .doc(notificationId);

    return db
      .runTransaction((transaction) =>
        transaction.get(sfDocRef).then(() => {
          transaction.update(sfDocRef, { isRead: true });
        })
      )
      .catch((error) => {
        console.log(error);
      });
  };

  return {
    markNotificationAsRead,
  };
}

export default useNotificationService;
