/* eslint-disable jsx-a11y/media-has-caption */
import PropTypes from 'prop-types';
// @mui
import { Box, Card, CardHeader, Grid } from '@mui/material';
// components

// ----------------------------------------------------------------------

InterviewResponseAnswersCard.propTypes = {
  response: PropTypes.object,
};

export default function InterviewResponseAnswersCard({ response }) {
  const { responses } = response;

  return (
    <Box sx={{ mt: 3, mb: 3 }}>
      <Grid container spacing={4}>
        {responses.map((response) => (
          <Grid item md={6} sm={12}>
            <Card>
              <CardHeader title={response.question} sx={{ mb: 2 }} />
              <video controls controlsList="nodownload" style={{ height: '40vh', width: '100%' }}>
                <source src={response?.videoAnswerUrl} />
              </video>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
