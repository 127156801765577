import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import firebase from "firebase";
import { useAtom } from "jotai";
import { useCollectionData } from "react-firebase-hooks/firestore";
// @mui
import {
  Box,
  List,
  Badge,
  Divider,
  Typography,
  ListItemText,
  ListItemButton,
  Button
} from "@mui/material";
// utils

import { fToNow } from "../../../../utils/formatTime";
// constants
import {
  PATH_INTERVIEW,
  PATH_VACANCY,
  PATH_USER,
} from "../../../../shared/routes/paths";
// services
import { useNotificationService } from "../../../../shared/services";
// components
import {
  Iconify,
  Scrollbar,
  MenuPopover,
} from "../../../../shared/components/base";
import { IconButtonAnimate } from "../../../../shared/components/animate";
import { userRecordAtom, userAtom } from "../../../../shared/state/atoms";
import { firestoreKeys } from "../../../../shared/utils/constants";
import { useAesCrypto } from "use-crypto";

// ----------------------------------------------------------------------

export default function NotificationsPopover({ users }) {
  const { decrypt } = useAesCrypto("&Rw!mx[[s9'L1I-xP<pkHFv4@:O?2g");
  const [userRecord] = useAtom(userRecordAtom);
  const [user] = useAtom(userAtom);

  const [notifications, setNotifications] = useState([]);


  const [notificationsData] = useCollectionData(
    firebase
      .firestore()
      .collection(firestoreKeys.COMPANY_USERS)
      .doc(userRecord.id)
      .collection(firestoreKeys.NOTIFICATIONS)
      .where("isRead", "==", false),
    { idField: "documentId" }
  );

  useEffect(() => {
    setNotifications(notificationsData || []);
  }, [notificationsData]);

  const { markNotificationAsRead } = useNotificationService();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const markAllAsRead = () => {
    const promises = notifications.map((notification) => {
      return markNotificationAsRead(userRecord.id, notification.documentId)
        .then(() => {
          // Update state after marking each notification
          setNotifications((prevNotifications) =>
            prevNotifications.filter(
              (item) => item.documentId !== notification.documentId
            )
          );
        })
        .catch((error) => {
          console.error(
            `Error marking notification as read: ${notification.documentId}`,
            error
          );
        });
    });

    Promise.all(promises)
      .then(() => {
        // All notifications have been marked as read
      })
      .catch((error) => {
        // Handle any errors that occur during the updates
        console.error("Error marking notifications as read:", error);
      });
  };


  return (
    <>
      <IconButtonAnimate
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={notifications?.length} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", py: 2, px: 2.5 }}>
  <Box sx={{ flexGrow: 1 }}>
    <Typography variant="subtitle1">Notifications</Typography>
    <Typography variant="body2" sx={{ color: "text.secondary" }}>
      You have {notifications?.length} unread messages
    </Typography>
  </Box>
  <Button onClick={markAllAsRead} variant="contained" sx={{ alignSelf: "flex-end" }}>Clear All</Button>
</Box>


        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List disablePadding>
            {notifications?.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                handleClose={handleClose}
              />
            ))}
          </List>
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.object,
  handleClose: PropTypes.func,
};

function NotificationItem({ notification, handleClose }) {
  const navigate = useNavigate();
  const [userRecord] = useAtom(userRecordAtom);
  const { markNotificationAsRead } = useNotificationService();

  const handleNavigation = () => {
    if (notification.type === "vacancy") {
      navigate(PATH_VACANCY.profile(notification.id));
      markNotificationAsRead(userRecord.id, notification.documentId);
    } else if (notification.type === "New Video Resume") {
      navigate(PATH_USER.profile(notification.id));
      markNotificationAsRead(userRecord.id, notification.documentId);
    } else {
      navigate(PATH_INTERVIEW.profile(notification.id));
      markNotificationAsRead(userRecord.id, notification.documentId);
    }
    handleClose();
  };

  return (
    <ListItemButton
      onClick={handleNavigation}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(notification.isUnRead && {
          bgcolor: "action.selected",
        }),
      }}
    >
      <ListItemText
        primary={notification.text}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Iconify
              icon="eva:clock-outline"
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {fToNow(notification.meta.createdDate.toDate())}
          </Typography>
        }
      />
    </ListItemButton>
  );
}
