import { db } from './firebase';
import { firestoreKeys } from '../utils/constants';

function useShortlistService() {
  const shortlistUser = (id, companyUser, user, companyId) => {    
    db.collection(firestoreKeys.SHORTLIST).add({
      companyId,
      user: {
        id,
        firstName: user?.details?.firstName,
        lastName: user?.details?.lastName,
        jobTitle: user?.role?.position ?? '',
        location: user?.details?.location ?? '',
        photoUrl: user?.content?.photoUrl ?? '',
        emailAddress: user?.contact?.emailAddress,
        token: user?.token ?? ''
      },
      shortlistedBy: {
        id: companyUser.id,
        name: `${companyUser.details.firstName} ${companyUser.details.lastName}`,
        emailAddress: companyUser.contact.emailAddress,
        photoUrl: companyUser.details?.photoUrl ?? '',
      },
    });
  }

  const unshortlistUser = (id) => db.collection(firestoreKeys.SHORTLIST).doc(id).delete();

  return { shortlistUser, unshortlistUser };
}

export default useShortlistService;
