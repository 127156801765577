import PropTypes from "prop-types";
import React from "react";
// @mui
import { TableRow, TableCell, Button } from "@mui/material";
// constants

import { formatTimestampToDate } from "../../shared/utils";

import { db } from "src/shared/services/firebase";
import { useToast } from "src/shared/hooks";

// ----------------------------------------------------------------------

ShareAccessListRow.propTypes = {
  row: PropTypes.object,
  onFetchData: PropTypes.func,
};

// ----------------------------------------------------------------------

export default function ShareAccessListRow({ row, onFetchData }) {
  const { showSuccess, showError } = useToast();
  const handleDisableClick = async () => {
    try {
      await db.collection("companies").doc(row.id).update({
        shareAccess: false,
      });
      onFetchData();
      showSuccess("Successfully Disabled Access");
    } catch (error) {
      console.error("Error disabling share access:", error);
      showError("Could Not Disable Access");
    }
  };

  return (
    <TableRow hover>
      <TableCell align="left">{row.contact.emailAddress}</TableCell>
      <TableCell align="left">{row.details.name}</TableCell>
      <TableCell align="left">
        <Button variant="contained" onClick={handleDisableClick}>
          Disable
        </Button>
      </TableCell>
    </TableRow>
  );
}
