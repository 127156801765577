import React from "react";
import firebase from "firebase";
import { db } from "./firebase";
// constants
import { firestoreKeys } from "../utils/constants";

export default function useInterviewService() {
  const [isBusy] = React.useState(false);

  const createInterview = (data) =>
    db.collection(firestoreKeys.INTERVIEWS).add({
      ...data,
      meta: {
        createdDate: firebase.firestore.FieldValue.serverTimestamp(),
        modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      },
    });

  const updateInterview = (interviewId, data) =>
    db.collection(firestoreKeys.INTERVIEWS).doc(interviewId).update(data);

  const updateInterviewResponse = (interviewId, responseId, data) =>
    db
      .collection(firestoreKeys.INTERVIEWS)
      .doc(interviewId)
      .collection(firestoreKeys.RESPONSES)
      .doc(responseId)
      .update(data);

  const updateInterviewNote = (interviewId, responseId, data) =>
    db
      .collection(firestoreKeys.INTERVIEWS)
      .doc(interviewId)
      .collection(firestoreKeys.RESPONSES)
      .doc(responseId)
      .update(data);

  const toggleInterviewResponseShorlist = async (
    interviewId,
    responseId,
    data
  ) => {
    try {
      await db
        .collection(firestoreKeys.INTERVIEWS)
        .doc(interviewId)
        .collection(firestoreKeys.RESPONSES)
        .doc(responseId)
        .update(data);

      // Fetch and return the updated document data
      const updatedDocRef = await db
        .collection(firestoreKeys.INTERVIEWS)
        .doc(interviewId)
        .collection(firestoreKeys.RESPONSES)
        .doc(responseId)
        .get();

      const updatedDocData = updatedDocRef.data();
      return updatedDocData;
    } catch (error) {
      console.error("Error updating Firestore document:", error);
      // You can handle the error here, such as logging it or displaying a message to the user
      throw error; // Re-throw the error to propagate it to the caller if needed
    }
  };

  return {
    isBusy,
    createInterview,
    updateInterview,
    updateInterviewResponse,
    updateInterviewNote,
    toggleInterviewResponseShorlist,
  };
}
