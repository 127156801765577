import PropTypes from 'prop-types';
import firebase from 'firebase';
import { useAtom } from 'jotai';
import { useDocumentData } from 'react-firebase-hooks/firestore';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
// hooks
import { userRecordAtom } from '../../../../shared/state/atoms';
// components
import { MyAvatar } from '../../../../shared/components/base';
// constnats
import { firestoreKeys } from '../../../../shared/utils/constants';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const [userRecord] = useAtom(userRecordAtom);
  const [company] = useDocumentData(
    firebase.firestore().collection(firestoreKeys.COMPANIES).doc(userRecord.companyId),
    { idField: 'id' }
  );

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          bgcolor: 'transparent',
        }),
      }}
    >
      <MyAvatar title={userRecord?.details.firstName} photoUrl={company?.details?.photoUrl} />

      <Box
        sx={{
          ml: 2,
          transition: (theme) =>
            theme.transitions.create('width', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(isCollapse && {
            ml: 0,
            width: 0,
          }),
        }}
      >
        <Typography variant="subtitle2" noWrap>
          {`${userRecord?.details?.firstName} ${userRecord?.details?.lastName}`}
        </Typography>
        <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
          {userRecord?.type}
        </Typography>
      </Box>
    </RootStyle>
  );
}
