import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
// @mui
import { styled, useTheme } from "@mui/material/styles";
import { Box, Stack, Drawer } from "@mui/material";
// utils
import cssStyles from "../../../../utils/cssStyles";
// config
import { NAVBAR } from "../../../config";
// hooks
import { useResponsive, useCollapseDrawer } from "../../../../shared/hooks";
// state
import { userRecordAtom } from "../../../../shared/state/atoms";
// components
import { Logo, Scrollbar } from "../../../../shared/components/base";
import { NavSectionVertical } from "../../../../shared/components/nav-section";
import navConfig from "./NavConfig";
import NavbarAccount from "./NavbarAccount";
import useAccountDetails from "src/modules/account/hooks/useAccountDetails";
import { db } from "src/shared/services/firebase";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();
  const user = useAtom(userRecordAtom);
  const { userRecord } = useAccountDetails();
  const companyId = userRecord?.companyId;
  const [share, setShare] = useState(false);
  const [loading, setLoading] = useState(true); // State for loading
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");
  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        // Fetch the company document with the specific companyId
        const companyDoc = await db
          .collection("companies")
          .doc(companyId)
          .get();

        // Check if the document exists and if shareAccess is true
        if (companyDoc.exists && companyDoc.data().shareAccess === true) {
          setShare(true);
        }
      } catch (error) {
        console.error("Error fetching company:", error);
      } finally {
        setLoading(false); // Stop loading once data is fetched
      }
    };

    if (companyId) {
      fetchCompany();
    } else {
      setLoading(false); // Stop loading if there's no companyId
    }
  }, [companyId]); // Add companyId as a dependency to re-fetch if it changes

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: "center" }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Logo />
        </Stack>

        <NavbarAccount isCollapse={isCollapse} />
      </Stack>

      <NavSectionVertical
        navConfig={navConfig(user, share, companyId)}
        isCollapse={isCollapse}
      />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      {loading ? ( // Conditional rendering based on loading state
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        ></Box>
      ) : (
        <>
          {!isDesktop && (
            <Drawer
              open={isOpenSidebar}
              onClose={onCloseSidebar}
              PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
            >
              {renderContent}
            </Drawer>
          )}

          {isDesktop && (
            <Drawer
              open
              variant="persistent"
              onMouseEnter={onHoverEnter}
              onMouseLeave={onHoverLeave}
              PaperProps={{
                sx: {
                  width: NAVBAR.DASHBOARD_WIDTH,
                  borderRightStyle: "dashed",
                  bgcolor: "background.default",
                  transition: (theme) =>
                    theme.transitions.create("width", {
                      duration: theme.transitions.duration.standard,
                    }),
                  ...(isCollapse && {
                    width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
                  }),
                  ...(collapseHover && {
                    ...cssStyles(theme).bgBlur(),
                    boxShadow: (theme) => theme.customShadows.z24,
                  }),
                },
              }}
            >
              {renderContent}
            </Drawer>
          )}
        </>
      )}
    </RootStyle>
  );
}
