// routes
import {
  PATH_DASHBOARD,
  PATH_SHORTLIST,
  PATH_PURCHASES,
  PATH_ACCOUNT_DETAILS,
  PATH_SEARCH,
  PATH_VACANCY,
  PATH_INTERVIEW,
  TEMPORARY_ACCESS,
  SHARE_ACCESS,
} from "../../../../shared/routes/paths";
// components
import { Iconify } from "../../../../shared/components/base";

// ----------------------------------------------------------------------

const navConfig = (user, share, companyId) => {
  const defaultItems = [
    {
      items: [
        {
          title: "dashboard",
          path: PATH_DASHBOARD.root,
          icon: <Iconify icon={"eva:grid-outline"} />,
        },
      ],
    },
    {
      items: [
        {
          title: "search",
          path: PATH_SEARCH.list,
          icon: <Iconify icon={"eva:search-outline"} />,
        },
      ],
    },
    {
      items: [
        {
          title: "purchase history",
          path: PATH_PURCHASES.list,
          icon: <Iconify icon={"eva:clock-outline"} />,
        },
      ],
    },
    {
      items: [
        {
          title: "shortlist",
          path: PATH_SHORTLIST.list,
          icon: <Iconify icon={"eva:list-outline"} />,
        },
      ],
    },
    {
      items: [
        {
          title: "job posts",
          path: PATH_VACANCY.list,
          icon: <Iconify icon={"eva:briefcase-outline"} />,
        },
      ],
    },
    {
      items: [
        {
          title: "interviews",
          path: PATH_INTERVIEW.list,
          icon: <Iconify icon={"eva:clipboard-outline"} />,
        },
      ],
    },
  ];

  if (share) {
    defaultItems.push({
      items: [
        {
          title: "temporary access",
          path: TEMPORARY_ACCESS.list,
          icon: <Iconify icon={"academicons:open-access"} />,
        },
      ],
    });
  }
  if (companyId === "KkVJ6EqU0C4qzHl5exp1") {
    defaultItems.push({
      items: [
        {
          title: "allowed sharing",
          path: SHARE_ACCESS.list,
          icon: <Iconify icon={"academicons:hal"} />,
        },
      ],
    });
  }

  if (user[0].type === "Main")
    defaultItems.push({
      items: [
        {
          title: "account details",
          path: PATH_ACCOUNT_DETAILS.profile,
          icon: <Iconify icon={"eva:person-outline"} />,
        },
      ],
    });

  return defaultItems;
};

export default navConfig;
