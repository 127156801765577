import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Card, Typography, CardHeader, Stack } from "@mui/material";
// components
import { Iconify } from "../../../../shared/components/base";

// ----------------------------------------------------------------------

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

InterviewResponseDetailsCard.propTypes = {
  response: PropTypes.object,
};

export default function InterviewResponseDetailsCard({ response }) {
  const { user } = response;

  return (
    <Card sx={{ mb: 3, minHeight: "95%" }}>
      <CardHeader title="Details" />

      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row">
          <IconStyle icon={"eva:activity-outline"} />
          <Typography variant="body2">{response.status}</Typography>
        </Stack>

        <Stack direction="row">
          <IconStyle icon={"eva:person-outline"} />
          <Typography variant="body2">{`${user?.firstName} ${user?.lastName}`}</Typography>
        </Stack>

        <Stack direction="row">
          <IconStyle icon={"eva:text-outline"} />
          <Typography variant="body2">{user?.position}</Typography>
        </Stack>

        <Stack direction="row">
          <IconStyle icon={"eva:briefcase-outline"} />
          <Typography variant="body2">{user?.company}</Typography>
        </Stack>

        <Stack direction="row">
          <IconStyle icon={"eva:pin-outline"} />
          <Typography variant="body2">{user.location}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
