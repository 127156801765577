import { useParams } from "react-router";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { db } from "../services/firebase";
// @mui
import {
  Container,
  Grid,
  Typography,
  AppBar,
  Toolbar,
  Divider,
  CircularProgress,
} from "@mui/material";
// routes
// hooks
import useInterviewResponseDetails from "src/modules/interviews/InterviewResponseDetails/hooks/useInterviewResponseDetails";
// components
import { Page } from "../components/base";

import {
  InterviewResponseAnswersCard,
  InterviewResponseDetailsCard,
} from "src/modules/interviews/InterviewResponseDetails/components";

// ----------------------------------------------------------------------

export default function TempInterviewResponseDetails() {
  const { interviewId, responseId } = useParams();
  const { response } = useInterviewResponseDetails(interviewId, responseId);

  const [loading, setLoading] = useState(true); // Added loading state
  const [renderInterview, setRenderInterview] = useState(false); // Added render interview state
  const [showContent, setShowContent] = useState(false); // Added show content state
  const location = useLocation();

  useEffect(() => {
    const fetchTempUrls = async () => {
      setLoading(true); // Set loading to true before fetching data

      try {
        const tempUrlsSnapshot = await db.collection("temporaryURLs").get();
        const tempUrlsDocs = tempUrlsSnapshot.docs;

        const tempUrlsData = tempUrlsDocs.map((doc) => ({
          id: doc.id,
          url: doc.data().tempURL,
        }));

        const shouldRender = tempUrlsData.some((data) =>
          location.pathname.includes(data.url)
        );

        if (shouldRender) {
          setRenderInterview(true);
        }

        // Delete each document after processing
      } catch (error) {
        console.error("Error fetching or deleting temporary URLs:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching and deleting data
      }
    };

    fetchTempUrls();
  }, [location.pathname]);

  useEffect(() => {
    if (response) {
      setShowContent(true);
    }
  }, [response]);

  return (
    <React.Fragment>
      {loading ? (
        <div
          style={{
            background: "black",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : showContent ? (
        // Display interview details or message
        <Page title="Interview Response Details">
          {renderInterview ? (
            <Container maxWidth="lg">
              <AppBar
                position="static"
                sx={{
                  marginTop: "5%",
                  marginBottom: "5%",
                  borderRadius: "15px",
                }}
              >
                <Toolbar>
                  <Typography variant="h6" component="div">
                    Shared Interview
                  </Typography>
                </Toolbar>
              </AppBar>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} sx={{ minHeight: "100%" }}>
                  <InterviewResponseDetailsCard
                    response={response}
                    sx={{ minHeight: "100%" }}
                  />
                </Grid>
              </Grid>

              <Divider />
              <InterviewResponseAnswersCard response={response} />
              <Divider />
            </Container>
          ) : (
            <Typography variant="h6" align="center" sx={{ marginTop: 10 }}>
              Cannot view this shared interview.
            </Typography>
          )}
        </Page>
      ) : (
        // In case there's a delay in loading response data
        <div style={{ minHeight: "100vh" }}>
          <CircularProgress color="secondary" />
        </div>
      )}
    </React.Fragment>
  );
}
