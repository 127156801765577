import React, { createContext, useState, useEffect } from "react";
import firebase from "firebase";
import { firestoreKeys } from "src/shared/utils/constants";

const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await firebase
        .firestore()
        .collection(firestoreKeys.VACANCIES)
        .where("status", "==", "Active")
        .get();
      const newData = snapshot.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        .filter(
          (vacancy) =>
            typeof vacancy.details.location === "object" &&
            !Object.values(vacancy.details.location).some(
              (value) => value === ""
            ) &&
            vacancy.details.rateType
        );
      newData.sort((a, b) => b.meta.createdDate - a.meta.createdDate);
      setData(newData);
    };

    // Fetch data initially
    fetchData();

    // Fetch data every 5 minutes
    const intervalId = setInterval(fetchData, 5 * 60 * 1000);

    // Clean up interval to prevent memory leaks
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array

  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};

export { DataContext, DataProvider };
