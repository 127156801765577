import React from 'react';
import firebase from 'firebase';
import { useAtom } from 'jotai';
import { useAesCrypto } from 'use-crypto';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
// services
import { useAuthService, useCompanyService, useCompanyUserService } from '../../../shared/services';
// hooks
import { useMapper, useToast } from '../../../shared/hooks';
// state
import { userRecordAtom } from '../../../shared/state/atoms';
// constants
import { firestoreKeys } from '../../../shared/utils/constants';

function useAccountDetails() {
  const [userRecord] = useAtom(userRecordAtom);
  const { encrypt } = useAesCrypto("&Rw!mx[[s9'L1I-xP<pkHFv4@:O?2g");
  const { mapCompany, mapCompanyUser } = useMapper();
  const [companyData] = useDocumentData(
    firebase.firestore().collection(firestoreKeys.COMPANIES).doc(userRecord.companyId),
    { idField: 'id' }
  );
  const [companyUsersData] = useCollectionData(
    firebase.firestore().collection(firestoreKeys.COMPANY_USERS).where('companyId', '==', userRecord.companyId),
    { idField: 'id' }
  );
  const [company, setCompany] = React.useState();
  const [companyUsers, setCompanyUsers] = React.useState([]);
  const [isBusy, setIsBusy] = React.useState(false);
  const { signUp } = useAuthService();
  const { updateCompany } = useCompanyService();
  const { createUser } = useCompanyUserService();
  const { showSuccess, showError } = useToast();

  React.useEffect(() => {
    if (companyData) {
      setCompany(mapCompany(companyData));
    }
  }, [companyData]);

  React.useEffect(() => {
    if (companyUsersData) {
      const mappedList = companyUsersData.map((user) => mapCompanyUser(user));
      setCompanyUsers(mappedList);
    }
  }, [companyUsersData]);

  const handleUserSignUp = (data) => {
    const userData = {
      companyId: userRecord.companyId,
      contact: {
        emailAddress: encrypt(data.emailAddress),
        contactNumber: encrypt(data.cellNumber),
      },
      details: {
        companyName: encrypt(company.details.name),
        firstName: encrypt(data.firstName),
        lastName: encrypt(data.lastName),
        jobTitle: encrypt(data.jobTitle),
        location: encrypt(data.location),
      },
      statistics: {
        jobPostsActive: 0,
        odisPurchased: 0,
        videoResumesPurchased: 0,
      },
      status: 'Active',
      type: 'Sub',
    };
    setIsBusy(true);
    return signUp(data.emailAddress, data.password)
      .then((res) => {
        createUser(res.user.uid, userData)
          .then(() => {
            updateCompany(company.id, {
              details: { ...company.details, numberOfUsers: Number(company.details.numberOfUsers) + 1 },
            });
            showSuccess('The sub account was successfully created.');
          })
          .catch((error) => {
            console.log(error);
            showError(error.message);
          });
      })
      .catch(() => showError('Failed to create sub user account.'))
      .finally(() => setIsBusy(false));
  };

  return { isBusy, userRecord, company, companyUsers, handleUserSignUp };
}

export default useAccountDetails;
