import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/functions';

const SitemapFetcher = () => {
  const [sitemap, setSitemap] = useState(null); // Initially set sitemap to null
  const [loading, setLoading] = useState(true); // Initially set loading state to true

  const getSitemap = async () => {
    const fetchSitemap = firebase.functions().httpsCallable("generateSitemap");

    try {
      const result = await fetchSitemap();
      const urls = result.data;
      const xmlSitemap = generateXmlSitemap(urls);
      setSitemap(xmlSitemap);
    } catch (error) {
      console.error('Error fetching sitemap:', error);
    } finally {
      setLoading(false); // Set loading state to false once fetching is complete
    }
  };

  const generateXmlSitemap = (urls) => {
    let xml = '<?xml version="1.0" encoding="UTF-8"?>\n';
    xml += '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n';
    
    urls.forEach(url => {
      xml += '<url>\n';
      xml += `<loc>${url}</loc>\n`;
      xml += '<changefreq>daily</changefreq>\n';
      xml += '<priority>0.8</priority>\n';
      xml += '</url>\n';
    });

    xml += '</urlset>\n';
    return xml;
  };

  useEffect(() => {
    getSitemap();
  }, []);

  // Return the XML string directly if loading, otherwise return null
  return loading ? null : sitemap;
};

export default SitemapFetcher;
